import React, { useEffect, useState, useRef, useMemo } from "react";
import {
    Tabs,
    Tab,
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Typography,
    CircularProgress,
    Switch,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    DialogContentText,
    Alert,
    InputAdornment,
    IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Delete as DeleteIcon } from "@mui/icons-material";
import axios from "axios";
import { useSelector } from "react-redux";
import RenderLogsDialog from "../../utils/Logs";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

export default function Subdomains() {
    const userData = useSelector((state) => state.userData);
    const user = userData.user_name;
    const ref = useRef();
    const [value, setValue] = useState(0);
    const tabConfigs = [
        {
            type: "thegametub",
            headingPrefix: "TheGameTub",
        },
        {
            type: "populargamesforyou",
            headingPrefix: "PopularGamesForYou",
        },
        {
            type: "quiztwiz",
            headingPrefix: "QuizTwiz",
        },
    ];
    const accessibleTabs =
        userData.user_type === "admin"
            ? tabConfigs
            : tabConfigs.filter((tab) =>
                userData?.access?.arbitrages?.includes(tab?.type)
            );
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [subdomainData, setSubdomainData] = useState(
        accessibleTabs[value]?.type === "thegametub"
            ? {
                  company: "",
                  client: "",
                  id: "",
                  ga: "",
                  clarity: "",
                  player: false,
                  ui: "default",
                  popupAdEnable: true,
                  adType: "adsense",
                  status: "active",
                  logsData: [],
              }
            : accessibleTabs[value]?.type === "populargamesforyou"
            ? {
                  company: "",
                  client: "",
                  id: "",
                  ga: "",
                  clarity: "",
                  popupAdEnable: true,
                  adType: "adsense",
                  status: "active",
                  logsData: [],
              }
            : {
                  company: "",
                  client: "",
                  id: "",
                  ga: "",
                  clarity: "", 
                  status: "active",
                  logsData: [],
              }
    );
    const [subdomains, setSubdomains] = useState({
        thegametub: [],
        populargamesforyou: [],
        quiztwiz: [],
    });
    const [filteredData, setFilteredData] = useState({
        thegametub: [],
        populargamesforyou: [],
        quiztwiz: [],
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [loading, setLoading] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [gagenerated, setGagenerated] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [gaSwitchVal, setGaSwitchVal] = useState(false);
    const [clientName, setClientName] = useState("");
    const [openUrlD, setOpenUrlD] = useState(false);
    const [showCopyAlert, setShowCopyAlert] = useState(false);
    const [showSaveAlert, setShowSaveAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [showEditAlert, setShowEditAlert] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [logsOpen, setLogsOpen] = useState(false);
    const [logs, setLogs] = useState([]);
    const [errors, setErrors] = useState({
        client: false,
        id: false,
        ga: false,
    });
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: "ascending",
    });

    // Fetch subdomains for the selected tab
    const fetchSubdomains = async (type) => {
        setLoading(true);
        try {
            const response = await  axios.get(
                process.env.REACT_APP_API_URL + `subdomain/get/${type}`
            );
            setSubdomains((prev) => ({
                ...prev,
                [type]: response?.data?.data,
            }));
        } catch (error) {
            console.error(`Error fetching ${type} subdomains:`, error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSubdomains(accessibleTabs[value]?.type);
    }, [value]);

    // Handle tab change
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
        setGaSwitchVal(false);
        setIsEditMode(false);
        setGagenerated(false);
        setIsFormOpen(false);
        setPage(0);
        setSuccess(false);
        setSearchInput("");
        setSortConfig({
            key: null,
            direction: "ascending",
        });
        const selectedType = accessibleTabs[newValue].type;
        if (selectedType === "thegametub") {
            setSubdomainData({
                company: "",
                client: "",
                id: "",
                ga: "",
                clarity: "",
                player: false,
                ui: "default",
                popupAdEnable : true,
                adType : "adsense",
                status: "active",
                logsData: [],
            });
        } else if (selectedType === "populargamesforyou") {
            setSubdomainData({
                company: "",
                client: "",
                id: "",
                ga: "",
                clarity: "",
                popupAdEnable: true,
                adType: "adsense",
                status: "active",
                logsData: [],
            });
        } else {
            setSubdomainData({
                company: "",
                client: "",
                id: "",
                ga: "",
                clarity: "",
                status: "active",
                logsData: [],
            });
        }
    };

    // Open form handler
    const handleAdd = () => {
        setIsFormOpen(true);
        setSubdomainData((prev) => ({
            ...prev,
            type: accessibleTabs[value]?.type,
        }));
    };

    // Close form handler
    const handleCloseForm = () => {
        setIsFormOpen(false);
         const selectedType = accessibleTabs[value]?.type;
         if (selectedType === "thegametub") {
             setSubdomainData({
                 company: "",
                 client: "",
                 id: "",
                 ga: "",
                 clarity: "",
                 player: false,
                 ui: "default",
                 popupAdEnable: true,
                 adType: "adsense",
                 status: "active",
                //  logsData: [],
             });
         } else if (selectedType === "populargamesforyou") {
             setSubdomainData({
                 company: "",
                 client: "",
                 id: "",
                 ga: "",
                 clarity: "",
                 popupAdEnable: true,
                 adType: "adsense",
                 status: "active",
                 //  logsData: [],
             });
         } else {
             setSubdomainData({
                 company: "",
                 client: "",
                 id: "",
                 ga: "",
                 clarity: "",
                 status: "active",
                 //  logsData: [],
             });
         }

        setIsEditMode(false);
        setGaSwitchVal(false);
        setGagenerated(false);
    };

    // Form input change handler
    const handleInputChange = (e) => {        
        const { name, value,type, checked } = e.target;
        // Determine whether to use `checked` or `value`
        const newValue = type === "checkbox" ? checked : value;
        setSubdomainData((prev) => ({
            ...prev,
            [name]: newValue,
        }));
        if (name === "client") {
            setClientName(value);
        }
    };

    const handleGa = async () => {
        if (clientName) {
            setLoading(true);
            try {
                let headersList = {
                    "Content-Type": "application/json",
                };

                let bodyContent = JSON.stringify({
                    name: clientName + `"(${accessibleTabs[value]?.type})"`,
                    url: `https//${clientName}.${accessibleTabs[value]?.type}.com`,
                    productType: `${accessibleTabs[value]?.type}`,
                });

                let reqOptions = {
                    url: process.env.REACT_APP_API_URL + "ga/generate",
                    method: "POST",
                    headers: headersList,
                    data: bodyContent,
                };

                let response = await axios.request(reqOptions);
                console.log(`GA(${accessibleTabs[value]?.type})- `, response.data);
                subdomainData.ga = response?.data?.id;
                setSuccess(true);
                setGagenerated(true);
                setGaSwitchVal(true);
            } catch (error) {
                console.error("Error generating GA:", error);
                alert("Error generating GA:");
            } finally {
                setLoading(false);
            }
        } else {
            alert("Please Enter Client First");
        }
    };

    //To add or update data in db
    const handleSave = async () => {
        // Validation check for required fields
        const newErrors = {
            client: !subdomainData.client,
            id: !subdomainData.id,
            ga: !subdomainData.ga,
        };

        setErrors(newErrors);

        // If there are errors, stop the save process
        if (Object.values(newErrors).includes(true)) {
            return;
        }
        try {
            const { type, ...data } = subdomainData;

            if (!isEditMode) {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}subdomain/add/${type}`,
                    { data, user }
                );
                if (response?.data?.success) {
                    setSubdomains((prev) => ({
                        ...prev,
                        [type]: [...prev[type], response?.data?.data],
                    }));
                    sendDiscordNotification(
                        "A New Domain has been added - " + subdomainData.client,["devops"], accessibleTabs[value]?.headingPrefix
                    );

                    setShowSaveAlert(true);
                    setTimeout(() => {
                        setShowSaveAlert(false);
                    }, 2000);
                } else {
                    setShowErrorAlert(true);
                    setErrMsg(response.data.error);
                    setTimeout(() => {
                        setShowErrorAlert(false);
                    }, 2000);
                    console.error(
                        "Failed to save subdomain:",
                        response.data.error
                    );
                }
            } else {
                const previousData = subdomains[type]?.find(
                    (subdomain) => subdomain._id === subdomainData._id
                );

                const response = await axios.put(
                    `${process.env.REACT_APP_API_URL}subdomain/update/${type}/${data._id}`,
                    { data, user }
                );
                if (response?.data?.success) {
                    // Update the relevant array in the state
                    setSubdomains((prev) => ({
                        ...prev,
                        [type]: prev[type].map((subdomain) =>
                            subdomain._id === data._id
                                ? response?.data?.data
                                : subdomain
                        ),
                    }));
                    // Send notification based on status update
                    if (previousData.status !== subdomainData.status) {
                        if (subdomainData.status === "active") {
                            sendDiscordNotification(
                                "A Domain has been REACTIVATED - " +
                                    subdomainData.client,
                                ["devops"],
                                accessibleTabs[value]?.headingPrefix, 
                            );
                        } else {
                            sendDiscordNotification(
                                "A Domain has been DEACTIVATED - " +
                                    subdomainData.client,
                                ["devops"],
                                accessibleTabs[value]?.headingPrefix
                            );
                        }
                    }
                    if (
                        type === "thegametub" &&
                        previousData.player !== subdomainData.player &&
                        subdomainData.player === true
                    ) {
                        sendDiscordNotification(
                            "Player is ENABLED for - " + subdomainData.client + ". Please create a videoplayer using KV for - " +
                                subdomainData.client,
                            ["videoplayer"],
                            accessibleTabs[value]?.headingPrefix,
                            true
                        );
                    }
                    setShowEditAlert(true);
                    setTimeout(() => {
                        setShowEditAlert(false);
                    }, 2000);
                } else {
                    setShowErrorAlert(true);
                    setErrMsg(response?.data?.error);
                    setTimeout(() => {
                        setShowErrorAlert(false);
                    }, 2000);
                    console.error(
                        "Failed to update subdomain:",
                        response.data.error
                    );
                }
            }
        } catch (error) {
            console.error("Error saving subdomain:", error);
        } finally {
            setClientName(subdomainData.client);
            handleCloseForm();
            await fetchSubdomains(accessibleTabs[value]?.type);
        }
    };

    // Delete subdomain handler
    const handleDeleteSubdomain = (id, type) => {
        setSubdomains((prev) => ({
            ...prev,
            [type]: prev[type].filter((subdomain) => subdomain.id !== id),
        }));
    };

    const handleEdit = async (_id) => {
        const selectedSubdomain = subdomains[accessibleTabs[value]?.type].find(
            (c) => c._id === _id
        );
        if (!selectedSubdomain) {
            throw new Error(`Client not found in data.`);
        }
        // Populate form data with selected subdomain for editing
        setSubdomainData({
            ...selectedSubdomain,
            type: accessibleTabs[value]?.type,
        });
        setGaSwitchVal(true);
        setIsEditMode(true);
        setIsFormOpen(true);
    };

    const openUrl = (client) => {
        setClientName(client);
        setOpenUrlD(true);
    };

    const handleCopy = () => {
        let url = ref.current.innerText;
        navigator.clipboard.writeText(url);
        setOpenUrlD(false);
        setShowCopyAlert(true);
        setTimeout(() => {
            setShowCopyAlert(false);
        }, 1000);
    };

    const sendDiscordNotification = async (msg,mention, type, player=false) => {
        let bodyContent = JSON.stringify({
            name: mention,
            content: msg,
            type : type,
            player : player
        });
        const res = await axios.request({
            url:
                process.env.REACT_APP_API_URL +
                "apidiscord/sendDiscordNotification",
            method: "POST",
            headers: { "Content-Type": "application/json" },
            data: bodyContent,
        });
    };

    const handleLogs = async (_id) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}subdomain/getLogs/${accessibleTabs[value]?.type}/${_id}`
            );
            setLogsOpen(true);
            setLogs(response?.data?.data || []);
        } catch (err) {
            console.error("Error fetching logs data:", err.message);
        }
    };

    // Memoized sorting and filtering logic
    const memoizedFilteredData = useMemo(() => {
        const currentType = accessibleTabs[value]?.type;
        const data = subdomains[currentType];

        if (!data) return [];

        // Apply search filter first
        let filteredData = !searchInput.trim()
            ? [...data]
            : data.filter(
                  ({ client, id, ga, status, company, ui, adType }) =>
                      (client &&
                          client
                              .toLowerCase()
                              .includes(searchInput.toLowerCase())) ||
                      (id && id.toString().includes(searchInput)) ||
                      (ga &&
                          ga
                              .toLowerCase()
                              .includes(searchInput.toLowerCase())) ||
                      (status &&
                          status
                              .toLowerCase()
                              .includes(searchInput.toLowerCase())) ||
                      (company &&
                          company
                              .toLowerCase()
                              .includes(searchInput.toLowerCase())) ||
                      (ui &&
                          ui
                              .toLowerCase()
                              .includes(searchInput.toLowerCase())) ||
                      (adType &&
                          adType
                              .toLowerCase()
                              .includes(searchInput.toLowerCase()))
              );

        // Apply sorting if a sort key is set
        // if (sortConfig.key) {
        //     filteredData.sort((a, b) => {
        //         // Handle potential undefined values
        //         const valueA = a[sortConfig.key] || "";
        //         const valueB = b[sortConfig.key] || "";

        //         // Case-insensitive string comparison
        //         const comparison = valueA
        //             .toLowerCase()
        //             .localeCompare(valueB.toLowerCase());

        //         // Reverse comparison based on sort direction
        //         return sortConfig.direction === "ascending"
        //             ? comparison
        //             : -comparison;
        //     });
        // }

        // Apply sorting if a sort key is set
        if (sortConfig.key) {            
            filteredData.sort((a, b) => {
                const valueA = a[sortConfig.key];
                const valueB = b[sortConfig.key];
                // Handle undefined values: move undefined to the end
                if (valueA === undefined && valueB === undefined) return 0;
                if (valueA === undefined)
                    return sortConfig.direction === "ascending" ? 1 : -1;
                if (valueB === undefined)
                    return sortConfig.direction === "ascending" ? -1 : 1;

                // Determine sorting logic based on type
                if (typeof valueA === "number" && typeof valueB === "number") {
                    return sortConfig.direction === "ascending"
                        ? valueA - valueB
                        : valueB - valueA;
                } else if (
                    typeof valueA === "string" &&
                    typeof valueB === "string"
                ) {
                    // Case-insensitive string comparison
                    const comparison = valueA
                        .toLowerCase()
                        .localeCompare(valueB.toLowerCase());
                    return sortConfig.direction === "ascending"
                        ? comparison
                        : -comparison;
                }
                // Fallback for type mismatch
                return 0;
            });
        }

        return filteredData;
    }, [subdomains, value, searchInput, sortConfig]);

    // Handle column sorting
    const handleSort = (key) => {
        setSortConfig((prevSort) => ({
            key,
            // Toggle direction if same key, otherwise start with ascending
            direction:
                prevSort.key === key && prevSort.direction === "ascending"
                    ? "descending"
                    : "ascending",
        }));
    };

    useEffect(() => {
        const currentType = accessibleTabs[value]?.type;
        setFilteredData((prev) => ({
            ...prev,
            [currentType]: memoizedFilteredData,
        }));
        setPage(0);
    }, [memoizedFilteredData, value]);

    const renderSubdomainTable = (type) => {
        const getSortIcon = (key) => {
            return (
                <span
                    style={{
                        display: "inline-flex",
                        alignItems: "center",
                        marginLeft: "4px",
                        color:
                            sortConfig.key === key
                                ? sortConfig.direction === "ascending"
                                    ? "#1565C0"
                                    : "#1565C0"
                                : "sky",
                    }}
                >
                    {sortConfig.key === key ? (
                        sortConfig.direction === "ascending" ? (
                            <ArrowUpwardIcon fontSize="small" />
                        ) : (
                            <ArrowDownwardIcon fontSize="small" />
                        )
                    ) : (
                        <ArrowUpwardIcon
                            fontSize="small"
                            style={{
                                opacity: 0.3,
                                transform: "scale(0.8)",
                            }}
                        />
                    )}
                </span>
            );
        };

        return (
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow
                            sx={{
                                backgroundColor: "#f5f5f5",
                                "& th": {
                                    color: "#555",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    padding: "12px 8px",
                                    fontSize: "0.9rem",
                                    borderBottom: "2px solid #ddd",
                                    whiteSpace: "nowrap", // Prevent column header wrapping
                                },
                            }}
                        >
                            {/* Set fixed widths for all headers */}
                            <TableCell
                                style={{
                                    width: "180px",
                                }}
                                onClick={() => handleSort("company")}
                                sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                        color: "#007BFF",
                                    },
                                }}
                            >
                                Company {getSortIcon("company")}
                            </TableCell>
                            <TableCell
                                style={{
                                    width: "140px",
                                }}
                                onClick={() => handleSort("client")}
                                sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                        color: "#007BFF",
                                    },
                                }}
                            >
                                Client {getSortIcon("client")}
                            </TableCell>
                            <TableCell
                                style={{
                                    width: "120px",
                                }}
                                onClick={() => handleSort("id")}
                                sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                        color: "#007BFF",
                                    },
                                }}
                            >
                                Channel ID {getSortIcon("id")}
                            </TableCell>
                            <TableCell
                                style={{
                                    width: "120px",
                                }}
                            >
                                GA Code
                            </TableCell>
                            <TableCell
                                style={{
                                    width: "120px",
                                }}
                            >
                                Clarity
                            </TableCell>
                            <TableCell
                                style={{
                                    width: "120px",
                                }}
                            >
                                Domain
                            </TableCell>
                            <TableCell
                                style={{
                                    width: "100px",
                                }}
                                onClick={() => handleSort("status")}
                                sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                        color: "#007BFF",
                                    },
                                }}
                            >
                                Status {getSortIcon("status")}
                            </TableCell>
                            <TableCell
                                style={{
                                    width: "120px",
                                }}
                            >
                                Edit Subdomain
                            </TableCell>
                            <TableCell
                                style={{
                                    width: "120px",
                                }}
                            >
                                Change Logs
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData[type]
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((subdomain) => (
                                <TableRow key={subdomain._id}>
                                    <TableCell>{subdomain.company}</TableCell>
                                    <TableCell>{subdomain.client}</TableCell>
                                    <TableCell>{subdomain.id}</TableCell>
                                    <TableCell>{subdomain.ga}</TableCell>
                                    <TableCell>{subdomain.clarity}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            color="info"
                                            onClick={() =>
                                                openUrl(subdomain.client)
                                            }
                                        >
                                            URL
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        <div
                                            style={{
                                                color:
                                                    subdomain.status ===
                                                    "active"
                                                        ? "green"
                                                        : "red",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {subdomain.status}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            style={{ color: "grey" }}
                                            onClick={() =>
                                                handleEdit(subdomain._id)
                                            }
                                        >
                                            <EditIcon />
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        {subdomain._id ? (
                                            <Button
                                                onClick={() =>
                                                    handleLogs(subdomain._id)
                                                }
                                            >
                                                <InfoIcon />
                                            </Button>
                                        ) : null}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 50, 100]}
                    component="div"
                    count={filteredData[type].length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(event) => {
                        setRowsPerPage(parseInt(event.target.value, 10));
                        setPage(0);
                    }}
                />
            </Paper>
        );
    };


    return (
        <Box sx={{ width: "100%" }}>
            {/* Tabs */}
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={value} onChange={handleTabChange}>
                    {accessibleTabs.map((tab, index) => (
                        <Tab key={index} label={tab.headingPrefix} />
                    ))
                }
                </Tabs>
            </Box>
            {/* TabPanels */}
            {accessibleTabs.map((tab, index) => (
                <TabPanel key={index} value={value} index={index}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingBottom: "10px",
                        }}
                    >
                        <Typography variant="h6">
                            {tab.headingPrefix} Subdomains
                        </Typography>

                        <Box
                            sx={{
                                width: "68%",
                                display: "flex",
                                justifyContent: "center",
                                zIndex: 1000,
                                position: "fixed",
                            }}
                        >
                            {showCopyAlert ? (
                                <div
                                    style={{
                                        width: "40%",
                                    }}
                                >
                                    <Alert variant="filled" severity="success">
                                        Copied!
                                    </Alert>
                                </div>
                            ) : showSaveAlert ? (
                                <div
                                    style={{
                                        width: "40%",
                                    }}
                                >
                                    <Alert variant="filled" severity="success">
                                        Saved Successfully!
                                    </Alert>
                                </div>
                            ) : showErrorAlert ? (
                                <div
                                    style={{
                                        width: "40%",
                                    }}
                                >
                                    <Alert variant="filled" severity="error">
                                        {errMsg}
                                    </Alert>
                                </div>
                            ) : (
                                <></>
                            )}
                            {/* {showErrorAlert && (
                                <div className="alert alert-danger">
                                    {errMsg}
                                </div>
                            )} */}

                            {showEditAlert && (
                                <span
                                    style={{
                                        width: "40%",
                                    }}
                                >
                                    <Alert variant="filled" severity="success">
                                        Updated Successfully !
                                    </Alert>
                                </span>
                            )}
                        </Box>
                        <span
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                gap: "15px",
                            }}
                        >
                            <TextField
                                label={`Search by Company, Client, GA${
                                    accessibleTabs[value]?.type === "thegametub"
                                        ? ", AdType, UI"
                                        : accessibleTabs[value]?.type ===
                                          "populargamesforyou"
                                        ? ", AdType"
                                        : ""
                                } or ID`}
                                variant="outlined"
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                                placeholder="Search"
                                sx={{ width: "370px" }}
                                InputProps={{
                                    endAdornment: searchInput && (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() =>
                                                    setSearchInput("")
                                                }
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <Button variant="contained" onClick={handleAdd}>
                                Add Subdomain
                            </Button>
                        </span>
                    </Box>
                    {/* Alert message */}

                    {renderSubdomainTable(tab.type)}
                </TabPanel>
            ))}
            {/* Show Change logs Dialog */}
            <RenderLogsDialog
                logs={logs}
                logsOpen={logsOpen}
                setLogsOpen={setLogsOpen}
            />
            {/* Add or Edit Subdomain Dialog */}
            <Dialog open={isFormOpen} onClose={handleCloseForm} fullWidth>
                <DialogTitle>
                    {isEditMode ? "Edit" : "Add"}{" "}
                    {accessibleTabs[value]?.headingPrefix} Subdomain
                </DialogTitle>
                <DialogContent>
                    <TextField
                        label="Company Name"
                        fullWidth
                        margin="normal"
                        name="company"
                        value={subdomainData.company}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Client Name"
                        fullWidth
                        margin="normal"
                        name="client"
                        value={subdomainData.client}
                        onChange={handleInputChange}
                        required
                        disabled={isEditMode}
                        error={errors.client}
                        helperText={
                            errors.client ? "Client Name is required" : ""
                        }
                    />
                    {!gaSwitchVal ? (
                        !gagenerated ? (
                            <Button
                                variant="contained"
                                onClick={handleGa}
                                sx={{ marginTop: 1.5 }}
                            >
                                {loading ? (
                                    <CircularProgress size={24} />
                                ) : success ? (
                                    "Generated Successfully!"
                                ) : (
                                    "Generate Your GA Code Here"
                                )}
                            </Button>
                        ) : (
                            <TextField
                                label="GA Code"
                                required
                                name="ga"
                                value={subdomainData.ga}
                                onChange={handleInputChange}
                                margin="normal"
                                error={errors.ga}
                                helperText={
                                    errors.ga ? "GA Code is required" : ""
                                }
                            />
                        )
                    ) : (
                        <TextField
                            label="GA Code"
                            required
                            name="ga"
                            value={subdomainData.ga}
                            onChange={handleInputChange}
                            margin="normal"
                            error={errors.ga}
                            helperText={errors.ga ? "GA Code is required" : ""}
                        />
                    )}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="body1">
                            Enter GA manually :
                        </Typography>
                        <Switch
                            checked={gaSwitchVal}
                            onChange={(e) => setGaSwitchVal(e.target.checked)}
                            color="primary"
                        />
                    </Box>
                    {subdomainData.adType === "adx" && (
                        <TextField
                            label="KV"
                            fullWidth
                            margin="normal"
                            name="kv"
                            value={subdomainData.client}
                            required
                            disabled={isEditMode}
                            error={errors.client}
                        />
                    )}

                    <TextField
                        value={subdomainData.id}
                        label="Channel Id"
                        type="number"
                        required
                        name="id"
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        error={errors.id}
                        helperText={errors.id ? "Channel ID is required" : ""}
                    />
                    <TextField
                        label="Clarity"
                        name="clarity"
                        value={subdomainData.clarity}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    {isEditMode && (
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="demo-simple-select-label">
                                Status
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Status"
                                name="status"
                                value={subdomainData.status}
                                onChange={handleInputChange}
                            >
                                <MenuItem value="active">Active</MenuItem>
                                <MenuItem value="inactive">Inactive</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                    {accessibleTabs[value]?.type !== "quiztwiz" && (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: "10px",
                                }}
                            >
                                {accessibleTabs[value]?.type ===
                                    "thegametub" && (
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel id="demo-simple-select-label">
                                            UI
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="UI"
                                            name="ui"
                                            value={subdomainData.ui}
                                            onChange={handleInputChange}
                                        >
                                            <MenuItem value="default">
                                                Default
                                            </MenuItem>
                                            <MenuItem value="gamestab">
                                                GamesTab
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                )}

                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="demo-simple-select-label">
                                        AdType
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="AdType"
                                        name="adType"
                                        value={subdomainData.adType}
                                        onChange={handleInputChange}
                                    >
                                        <MenuItem value="adsense">
                                            Adsense
                                        </MenuItem>
                                        <MenuItem value="adx">Adx</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: "10px",
                                }}
                            >
                                {accessibleTabs[value]?.type ===
                                    "thegametub" && (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography variant="body1">
                                            Player :
                                        </Typography>
                                        <Switch
                                            name="player"
                                            color="primary"
                                            label="Player"
                                            checked={subdomainData.player}
                                            onChange={handleInputChange}
                                        />
                                    </Box>
                                )}
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography variant="body1">
                                        PopupAdEnable :
                                    </Typography>
                                    <Switch
                                        name="popupAdEnable"
                                        color="primary"
                                        label="PopupAdEnable"
                                        checked={subdomainData.popupAdEnable}
                                        onChange={handleInputChange}
                                    />
                                </Box>
                            </div>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={loading}
                        onClick={handleCloseForm}
                        color="error"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        disabled={loading}
                        onClick={handleSave}
                    >
                        {loading ? (
                            <CircularProgress size={24} />
                        ) : isEditMode ? (
                            "Update"
                        ) : (
                            "Save"
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Dialog to show url */}
            <Dialog
                open={openUrlD}
                onClose={() => setOpenUrlD(false)}
                sx={{
                    "& .MuiDialog-paper": {
                        borderRadius: "10px",
                        padding: "10px",
                        backgroundColor: "#ffffff",
                        boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
                        border: "1px solid #e0e0e0",
                        maxWidth: "450px",
                        width: "73%",
                    },
                }}
            >
                <DialogContent>
                    <DialogContentText
                        sx={{
                            backgroundColor: "#f8f9fa",
                            borderRadius: "10px",
                            padding: "15px",
                            textAlign: "center",
                            fontFamily: "'Roboto Mono', monospace",
                            fontSize: "0.9rem",
                            color: "#333333",
                            border: "1px solid #d6d8db",
                            boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                    >
                        <code ref={ref}>
                            {`https://${clientName}.${accessibleTabs[value]?.type}.com`}
                        </code>
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{
                        justifyContent: "space-between",
                        padding: "0 20px 20px",
                        gap: "10px",
                    }}
                >
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => setOpenUrlD(false)}
                        sx={{
                            borderRadius: "8px",
                            borderColor: "#ff4d4f",
                            color: "#ff4d4f",
                            padding: "8px 16px",
                            textTransform: "none",
                            transition: "all 0.3s ease",
                            "&:hover": {
                                backgroundColor: "#ff4d4f",
                                color: "#fff",
                            },
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="info"
                        // color="primary"
                        onClick={handleCopy}
                        sx={{
                            borderRadius: "8px",
                            padding: "8px 16px",
                        }}
                    >
                        Copy
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
